@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');

/* @media screen and (min-width: 960px) {
    html { margin-left: calc(100vw - 100%); margin-right: 0; } 
} */

body {
    @apply text-grayblue-100;
}

.tw-container-small {
    @apply max-w-screen-lg mx-auto;
}

.tw-container {
    max-width: 1630px;
    @apply mx-auto px-6;
}

@media (min-width: 2160px) {
    .main-container {
        max-width: 1630px;
        @apply mx-auto px-6;
    }

    .header-container {
        max-width: 1630px;
        @apply mx-auto px-6;
    }

    .header-container .header {
        @apply px-0;
    }
}

.main-container.is-padding {
    @apply px-6 5xl:px-0 pt-6;
}

.main-container.is-padding-alt {
    @apply px-6 5xl:px-0;
}

.tw-container-fluid {
    @apply w-full px-6;
}

.tw-container.with-padding,
.tw-container-fluid.with-padding {
    @apply py-6;
}

/* Header */
.header {
    @apply flex flex-wrap text-grayblue-100 text-opacity-80 border-b px-6 py-6 gap-4;
}

.header div {
    @apply my-auto;
}

/* ----------- Heading */
h1, .h1 {
    @apply text-2xl lg:text-3xl xl:text-4xl font-bold;
}

h2, .h2 {
    @apply text-xl lg:text-2xl xl:text-3xl font-semibold;
}

h3, .h3 {
    @apply text-lg lg:text-xl xl:text-2xl font-semibold;
}

h4, .h4 {
    @apply text-base lg:text-lg xl:text-xl font-semibold;
}

h5, .h5 {
    @apply text-sm lg:text-base xl:text-lg font-semibold;
}

.pageHeading {
    @apply text-2xl font-bold text-gray-800;
}

.smallHeading {
    @apply text-gray-500 text-base uppercase font-semibold;
}

/* ----------- Navigation */
.nav-link {
    @apply
        text-gray-400
        hover:text-gray-500
        font-semibold tracking-normal
        flex py-1.5
        transition ease-in-out duration-200;
}

.nav-link.is-active {
    @apply
        text-white
        !important;
}

.nav-link.is-active svg{
    @apply
        text-cyan-100 text-lg
        !important;
}

.nav-link svg{
    width: 1em !important;
}

.nav-link svg{
    @apply
        text-lg
        !important;
}

.nav-link.is-dropdown-active {
    @apply 
        text-gray-300;
}

.side-link {
    @apply
        flex py-2 text-gray-400
        font-semibold
        hover:text-gray-300
        transition ease-in-out duration-200;
}

.side-link.tr-enabled {
    @apply
        text-gray-50;
}

.side-link.is-active {
    @apply text-gray-300;
}

.side-bg {
    @apply rounded bg-gray-600 bg-opacity-50 mx-8;
}

.side-bg div:first-child {
    @apply rounded-t;
}

.side-bg div:last-child {
    @apply rounded-b;
}

/* ----------- Buttons */
.button-normal { @apply px-8 py-2 rounded-md font-semibold focus:outline-none transition; }
.button-small { @apply px-4 py-2 rounded-md font-semibold focus:outline-none transition; }
.button-tiny { @apply px-2 py-0.5 rounded-md font-semibold focus:outline-none transition; }
.button-cyan { @apply bg-cyan-100 hover:opacity-70 text-white; }
.button-black { @apply bg-black hover:opacity-70 text-white; }
.button-yellow { @apply bg-yellow-100 hover:bg-yellow-60 text-black; }
.button-red { @apply bg-error-100 hover:bg-error-60 text-white; }
.button-green { @apply bg-success-100 hover:bg-success-60 text-white; }
.button-gray { @apply bg-grayblue-20 hover:opacity-70 text-grayblue-100; }
.button-disabled { @apply bg-grayblue-20 pointer-events-none text-grayblue-40; }

.button-red-text { @apply uppercase text-error-100 hover:text-error-60 transition; }
.button-cyan-text { @apply uppercase text-cyan-100 hover:text-cyan-60 transition; }

/* ----------- Buttons (old) */
.pill {
    @apply text-xs bg-yellow-20 uppercase text-yellow-100 px-1.5 py-0.5 rounded;
}

/* ----------- Content Lists */
/* .table-container { @apply border-2 rounded-lg border-grayblue-20; } */

.table-head {
    margin-bottom: 2px;
    @apply flex text-sm font-bold uppercase px-6 py-2 border-b;
}

.table-head button {
    @apply font-bold uppercase;
}

.table-row {
    @apply flex bg-white hover:bg-grayblue-20 hover:bg-opacity-30 px-6 py-2 border-b transition ease-in-out duration-300 text-xs md:text-sm lg:text-base;
}
/*
.table-row:last-child {
    @apply border-transparent rounded-b-lg;
}
*/

.table-none {
    @apply py-24 bg-white text-center font-semibold text-xs md:text-sm lg:text-base text-gray-600;
}

/* ----------- Dropdown */
.dropdown {
    max-height: 16rem;
    @apply z-10 transform bg-white rounded-lg shadow border border-gray-300 transition ease-in-out duration-300 absolute top-0 mt-12 w-48 overflow-y-scroll !important;
}

.dropdown.is-large {
    @apply w-64 !important;
}

.dropdown.is-right {
    @apply right-0 !important;
}

.dropdown button:first-child,
.dropdown div:first-child {
    @apply rounded-t-lg;
}

.dropdown button:last-child,
.dropdown div:last-child {
    @apply rounded-b-lg border-b-0 !important;
}

.dropdown.is-active {
    @apply opacity-100 translate-y-0;
}

.dropdown.not-active {
    @apply translate-y-2 opacity-0 pointer-events-none;
}

.dropdown .item {
    @apply block w-full hover:bg-gray-100 text-left px-4 py-2 border-b text-base focus:outline-none transition ease-in-out duration-300;
}

.dropdown .item.is-active {
    @apply bg-gray-200 hover:bg-gray-100;
}

/* ----------- Input */
.input-basic {
    @apply rounded block w-full p-1.5 border border-gray-200 focus:outline-none focus:border-gray-400 transition ease-in-out duration-300;
}

.loginBox {
    @apply flex border-b-2;
}

.loginBox .icon {
    @apply w-10 bg-gray-200 text-gray-700 my-auto bg-white py-3 text-center;
}

.loginBox input {
    @apply w-full block py-2 pr-2 bg-white focus:outline-none text-lg;
}

/* ------------ Document */
.inputDocument input,
.inputDocument textarea,
.inputDocument select {
    @apply rounded w-full block p-2 text-base bg-white border-gray-400 border focus:border-gray-900 placeholder-gray-400 text-grayblue-100 tracking-tight transition ease-in-out duration-300 focus:outline-none;
}

.inputDocument .panel-body label{
    @apply pointer-events-none;
}

.inputDocument label {
    @apply font-bold text-sm text-gray-900 uppercase block pb-2;
}

/* ------------ Schema */
.inputSchema input,
.inputSchema textarea,
.inputSchema select {
    @apply rounded w-full block px-2 py-1 text-base bg-white border-gray-400 border hover:border-gray-500 focus:border-gray-500 placeholder-gray-500 text-gray-900 font-semibold tracking-tight transition ease-in-out duration-300 focus:outline-none;
}

.inputSchema label {
    @apply font-semibold text-sm text-gray-900 uppercase block pb-1;
    pointer-events: auto !important;
}

/* ----------- Checkbox */
.fileCheckbox input[type=radio] {
    @apply hidden;
}

.fileCheckbox label {
    @apply block border-4 border-gray-300 rounded transition ease-in-out duration-200;
    pointer-events: auto !important;
}

.media-upload-button label{
    pointer-events: auto !important;
}

.fileCheckbox img {
    @apply w-32 h-32 object-cover;
}

.fileCheckbox input[type=radio]:checked + label {
    @apply border-4 border-teal-300;
    pointer-events: auto !important;
}

/* ---------- Object Groups */
.document-group .head {
    @apply font-semibold text-sm text-gray-500 uppercase block pb-1;
}

/* ---------- Panels */
.panel {
    @apply border-gray-900 border rounded-b-lg overflow-hidden;
}

.panel .panel-head {
    @apply text-sm font-bold py-3 px-4 bg-gray-900 text-gray-50;
}

.panel .panel-body.is-items {
    @apply p-0;
}

.panel .panel-body {
    @apply p-6 rounded-b-lg;
}

.panel-gray {
    @apply border-grayblue-60 !important;
}

.panel-gray .panel-head {
    @apply bg-grayblue-60 text-white !important;
}

/* React Popup */
.react-confirm-alert-overlay {
    @apply bg-black bg-opacity-80 !important;
}
.react-confirm-alert-body {
    @apply rounded-lg shadow-md border border-gray-100 font-sans !important;
}

/* Popup */
.modalPopup {
    height: max-content;
    @apply absolute px-5 py-5 bottom-0 bg-white rounded-lg shadow-lg border;
}

/* ----------- MDE */
.mde-tabs button:focus {
    @apply outline-none !important;
}

.mde-header {
    @apply bg-gray-50 rounded-t border-gray-200 !important;
}

.mde-text {
    @apply font-sans font-normal text-base text-gray-900 !important;
}

.react-mde {
    @apply rounded border-gray-400 border focus:border-gray-900 !important;
}

.mde-header .mde-tabs button {
    @apply px-2 rounded !important;
}

.mde-header .mde-tabs button.selected {
    @apply border-gray-300 !important;
}

.mde-header-item button[data-name="checked-list"]{
    @apply hidden !important;
}

.mde-preview .mde-preview-content h1, .mde-preview .mde-preview-content h2, .mde-preview .mde-preview-content h3, .mde-preview .mde-preview-content h4, .mde-preview .mde-preview-content h5{
    @apply border-none pb-0 mt-2 !important;
} 

.mde-textarea-wrapper textarea.mde-text, .mde-textarea-wrapper textarea.mde-preview{
    @apply !overflow-hidden;
    min-height: 150px;
}

.mde-preview .mde-preview-content h1 {
    font-size: 3em !important;
}

.mde-preview .mde-preview-content h2 {
    font-size: 2em !important;
}

.mde-preview .mde-preview-content h3 {
    font-size: 1.8em !important;
}

.mde-preview .mde-preview-content h4 {
    font-size: 1.4em;
}

.mde-preview .mde-preview-content h5 {
    font-size: 1.2em;
}

.mde-preview .mde-preview-content ul{
    list-style: disc;
}

.mde-preview .mde-preview-content ol{
    list-style: auto;
}


/* ----------- React Datetime Picker */
.react-datetime-picker__wrapper {
    @apply border-none !important;
}

.react-datetime-picker__inputGroup__input {
    @apply focus:outline-none;
}

.react-datetime-picker__clear-button {
    @apply hidden;
}

/* ----------- Toastify */
.Toastify__toast {
    @apply text-base rounded-lg !important;
}

.Toastify__toast--success {
    @apply bg-success-100 !important;
}

.Toastify__toast--error {
    @apply bg-error-100 !important; 
}

/* Track */
::-webkit-scrollbar {
    width: 5px;
    @apply transition ease-in-out duration-300;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    @apply bg-gray-600 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-500;
}

.sidebar{
    @apply overflow-auto;
}

.sidebar-auth{
    @apply mt-10 w-full;
}
